import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { fetchRecordsBalanceData } from '@/lib/service';
import styled from 'styled-components';

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserContent = () => {
  const {
    isLogin,
    onOpenLoginModal,
    onChangedLoginView,
    onSignOut,
    onCreditsChanged,
    creditsCount,
  } = useUserProvider();
  const [firstLetter, setFirstLetter] = useState<string>('');
  const { t } = useTranslation('common');
  const router = useRouter();

  // Dashboard、Settings、My Baby Conins、Payment History、Log out

  const items: MenuProps['items'] = [
    {
      key: 'Dashboard',
      label: <Link href={'/dashboard'}>{t('Dashboard')}</Link>,
    },
    {
      key: 'settings',
      label: <Link href={'/settings'}>{t('Settings')}</Link>,
    },
    // {
    //   key: 'my-photo',
    //   label: <Link href={'/my-photo'}>{t('MyPhotoGallery')}</Link>,
    // },
    {
      key: 'My Baby Coins',
      label: <Link href={'/my-credits'}>{t('My Baby Coins')}</Link>,
    },
    {
      key: 'billing-records',
      label: <Link href={'/billing-records'}>{t('PaymentHistory')}</Link>,
    },
    {
      key: 'logout',
      label: (
        <Link href={'/'} onClick={() => onSignOut()}>
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  const loadBanaceList = () => {
    fetchRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onCreditsChanged(res?.data?.balance);
        }
      })
      .catch((e) => {
        // message.error(e?.message);
      });
  };
  useEffect(() => {
    loadBanaceList();
  }, [isLogin, creditsCount]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user?.email?.length > 0) {
          const tempLetter = user?.email[0];
          setFirstLetter(tempLetter.toLocaleUpperCase());
        } else {
          setFirstLetter('');
        }
      } else {
        setFirstLetter(undefined);
      }
    });

    return () => {
      if (isLogin) {
        unsubscribe();
      } else {
        setFirstLetter(undefined);
      }
    };
  }, [isLogin]);

  return (
    <div className={styles.content}>
      {firstLetter != undefined ? (
        <CenterContent>
          <CenterContent style={{ marginRight: '5px' }}>
            <Image src={'/images/layouts/credit_icon.png'} alt={''} width={25} height={25} />
            <span className={styles.creditLabel}>{`${t('Baby Coins')}: `}</span>
            <span className={styles.creditCount}>{`${creditsCount ?? 0}`}</span>
          </CenterContent>

          <Dropdown menu={{ items }} placement="bottomRight" autoAdjustOverflow={false}>
            <Button
              className={styles.emailIcon}
              onClick={() => {
                router.push('/my-photo');
              }}
            >
              {firstLetter}
            </Button>
          </Dropdown>
        </CenterContent>
      ) : (
        <Button
          className={styles.login}
          onClick={() => {
            onOpenLoginModal(true);
            onChangedLoginView(true);
          }}
        >
          {`${t('SignIn_SignUp')}`}
        </Button>
      )}
    </div>
  );
};

export default UserContent;
