import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import classNames from 'classnames';
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import './index.less';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
// import { EventName } from '@/shared/event-types';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import { EventName } from '@/shared/event-types';
import { eventNames } from 'process';
import { CaretDownOutlined } from '@ant-design/icons';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

// export async function getServerSideProps() {
//   const res = await fetch(
//     `https://api.pietrastudio.com/commons/business-settings/DISPLAY/CATEGORY_NAV_DROPDOWN_ITEMS`,
//   );
//   console.log('res:', res);
//   return {
//     categoryNavDropdownItems: [],
//   };
// }

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname } = useRouter();
  const { width } = useScreenSize();
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation('common');

  const getMarginTop = useMemo(() => {
    let topMarginTop = '0px';
    // if (pathname.includes('create-baby-song')) {
    //   topMarginTop = '0px';
    // } else {
    if (width > 900) {
      topMarginTop = 'calc(var(--pietra-global-header-heigth) - 2px)';
    } else {
      topMarginTop = 'var(--pietra-global-mobile-header-heigth)';
    }
    // }
    if (!showHeader) {
      topMarginTop = '0px';
    }
    return topMarginTop;
  }, [pathname, showHeader, width]);

  const getShowHeader = useMemo(() => {
    // if (pathname.includes('create-baby-song') && width <= 900) {
    //   return false;
    // } else {
    return showHeader;
    // }
  }, [pathname, showHeader, width]);

  // app sub title
  const getNavItems = () => {
    switch (AppSettings.type) {
      case '1':
        return [
          {
            text: t('home'),
            path: '/',
          },
          {
            text: 'GPTs',
            path: '/gpts',
          },
          {
            text: t('FeaturedBooks'),
            path: '/recommended-books',
          },
          {
            text: t('guide'),
            path: '/category/guide',
          },
          {
            text: t('insight'),
            path: '/category/insight',
          },
          {
            text: t('poems'),
            path: '/category/poems',
          },
          {
            text: t('stories'),
            path: '/category/stories',
          },
        ];
      case '2':
        return [
          {
            text: t('home'),
            path: '/',
          },
          {
            text: 'GPTs',
            path: '/gpts',
          },
          {
            text: t('guide'),
            path: '/category/guide',
          },
          {
            text: t('insight'),
            path: '/category/insight',
          },
        ];
      case '3':
      case '5':
        return [
          // {
          //   text: t('Home'),
          //   path: '/',
          // },
          {
            text: t('Baby Birth Chart'),
            path: '/baby-astrology',
            eventName: EventName.header_BabyAstrology,
          },
          {
            text: t('Baby Songs Generator'),
            path: '/baby-songs-generator',
            eventName: EventName.header_BabySongsGenerator,
          },
          // {
          //   text: t('Baby Photo Generator'),
          //   path: '/AI-baby-generator',
          //   eventName: EventName.header_BabyPhotoGenerator,
          // },
          {
            text: t('BabyNames'),
            path: '/baby-name-generator',
            eventName: EventName.header_BabyNames,
          },
          // {
          //   text: t('Pricing'),
          //   path: '/plan',
          //   eventName: EventName.header_Pricing,
          // },
          {
            text: t('Blog'),
            path: '/blog',
            eventName: EventName.header_Blog,
            dropdownItems: [
              {
                text: t('pregnancy'),
                path: '/category/guide',
              },
              {
                text: t('parenting'),
                path: '/category/insight',
              },
            ],
          },
          {
            text: <><span>{t('Toolkits')}</span><CaretDownOutlined /></>,
            eventName: EventName.header_Toolkits,
            onClick: () => {
            },
            dropdownItems: [
              {
                text: t('Baby Birth Chart'),
                path: '/baby-astrology',
                eventName: EventName.header_BabyAstrology,
              },
              {
                text: t('Baby Songs Generator'),
                path: '/baby-songs-generator',
                eventName: EventName.header_BabySongsGenerator,
              },
              {
                text: t('Baby Photo Generator'),
                path: '/AI-baby-generator',
                eventName: EventName.header_BabyPhotoGenerator,
              },
              {
                text: 'GPTs',
                path: '/gpts',
                eventName: EventName.header_GPTs,
              },
            ],
          },
        ];
      case '4':
        return [
          {
            text: t('home'),
            path: '/',
          },
          {
            text: 'GPTs',
            path: '/gpts',
          },
          {
            text: t('theArtWorld'),
            path: '/category/guide',
          },
          {
            text: t('pinkGuide'),
            path: '/category/insight',
          },
        ];
      default:
        [];
    }
  };

  return (
    <div className={'layout'}>
      {head}
      {getShowHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={width <= 900}
            isMarketplaceHome={pathname === '/'}
            navItems={[...getNavItems()]}
          />
          {/* <SellOnPietraDialog isMobile={isMobile} isModalOpen={open} onCancel={toggle} /> */}
        </header>
      )}
      <main
        className={classNames('main', {
          mobile: width <= 900,
        })}
        style={{ marginTop: getMarginTop }}
      >
        {children}
      </main>
      {showFooter && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
